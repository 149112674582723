import * as React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@upperhand/playmaker';

import { enabledCustomerPreferences } from 'shared/utils/CustomerUtils';

import Header from './Header.jsx';

import SessionTabs from './SessionTabs.jsx';
import EditSession from './editSession/EditSession.jsx';

const styles = {
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Content({
  intl,
  selectedTab = 0,
  total = 0,
  eventId = null,
  isEditMode = false,
  minimized = false,
  isLoading = true,
  sessionId = null,
  onNextSession = undefined,
  onPreviousSession = undefined,
  event = null,
}) {
  const isTeamEvent = event?.isTeamEvent();
  const rsvpEnabled = enabledCustomerPreferences(['enable_rsvp']);
  const rsvpEnabledForEventType = event?.rsvpEventType
    ? enabledCustomerPreferences(
        ['enable_rsvp_sections'],
        [event.rsvpEventType]
      )
    : false;
  const rsvpEnabledForEvent = rsvpEnabled && rsvpEnabledForEventType;

  if (minimized) {
    return (
      <Header
        minimized={minimized}
        sessionId={sessionId}
        isEditMode={isEditMode}
        onNextSession={onNextSession}
        onPreviousSession={onPreviousSession}
        event={event}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <div style={styles.spinner}>
          <Spinner type="indeterminate" />
        </div>
      )}
      {!isLoading && (
        <div style={styles.content}>
          <Header
            sessionId={sessionId}
            isEditMode={isEditMode}
            onNextSession={onNextSession}
            onPreviousSession={onPreviousSession}
            event={event}
          />
          {isEditMode ? (
            <EditSession
              intl={intl}
              sessionId={sessionId}
              isTeamEvent={isTeamEvent}
            />
          ) : (
            <SessionTabs
              total={total}
              intl={intl}
              sessionId={sessionId}
              eventId={eventId}
              event={event}
              selectedTab={selectedTab}
              isTeamEvent={isTeamEvent}
              rsvpEnabled={rsvpEnabledForEvent}
            />
          )}
        </div>
      )}
    </>
  );
}

Content.propTypes = {
  intl: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  eventId: PropTypes.number,
  sessionId: PropTypes.string,
  total: PropTypes.number,
  event: PropTypes.object,
  onNextSession: PropTypes.func,
  onPreviousSession: PropTypes.func,
};

export default Content;
