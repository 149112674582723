import * as React from 'react';
import PropTypes from 'prop-types';

import Billing from 'containers/client/settings/billing/Billing.jsx';
import ClientBillingSettingsActions from 'containers/client/settings/billing/Actions';

import altContainer from 'shared/hocs/altContainer.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { ClientDataStore } from 'dataStores';

import NotAvailableState from './NotAvailableState.jsx';

function PaymentsMethods({
  intl,
  clientId,
  userId,
  accessToVault = false,
  drawerMode = false,
  disablePayLater = false,
  accountCreditAutoPay,
  accountCredits,
  clientDataStore = {},
}) {
  React.useEffect(() => {
    if (currentUser().isStaff()) {
      ClientBillingSettingsActions.mounted.defer(
        userId,
        clientId,
        accessToVault,
        true,
        accountCreditAutoPay,
        disablePayLater
      );
    }
  }, [accessToVault, clientId, userId, accountCreditAutoPay, disablePayLater]);

  const { clients } = clientDataStore;
  const client = clients.get(clientId);
  const managingIsAvailable = client.isClient() && client.get('login_enabled');
  const isStaff = currentUser().isStaff();
  const access = currentUser().access_to_vault;
  return (
    <div className="payments__list-container">
      {managingIsAvailable && (
        <Billing
          allowAccountCreditAutoPay
          drawerMode={drawerMode}
          staffMode={isStaff}
          access={access}
          customerUserId={clientId}
          userId={userId}
          accountCreditAutoPay={accountCreditAutoPay}
          accountCredits={accountCredits}
          disablePayLater={disablePayLater}
        />
      )}
      {!managingIsAvailable && <NotAvailableState intl={intl} />}
    </div>
  );
}

PaymentsMethods.propTypes = {
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  accessToVault: PropTypes.bool,
  drawerMode: PropTypes.bool,
  clientDataStore: PropTypes.object,
};

export default altContainer({
  stores: { clientDataStore: ClientDataStore },
})(PaymentsMethods);
