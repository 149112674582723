import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import { Button } from '@upperhand/playmaker';
import { FormattedMessage } from 'react-intl';

import CheckInActions from 'containers/events/admin/sessionSummaryDrawer/components/checkIn/actions.js';
import { messageId } from 'shared/utils/LocaleUtils';

import ConfirmationButtons from './ConfirmationButtons.jsx';

import SessionSummaryDrawerActions from '../Actions';
import EditSessionActions from './editSession/actions.js';
import NotesActions from './notes/actions';

function EditingFooter({ isActiveSubmit, onCancel, onConfirm }) {
  return (
    <ConfirmationButtons
      isActive={isActiveSubmit}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
}

function CheckInFooter({ numberOfClients: total }) {
  return (
    <Button
      fullWidth
      onClick={CheckInActions.confirmChanges}
      size="4x"
      textTransform="uppercase"
      type="primary"
    >
      <FormattedMessage
        id={messageId('.check_in', __filenamespace)}
        values={{ total }}
      />
    </Button>
  );
}

function Footer({
  editModes,
  checkInMode,
  isActiveSubmit,
  clientsToCheckInOutCount,
  isEditMode,
  selectedTab,
}) {
  const noteEditMode =
    editModes.get('admin_note') || editModes.get('client_note');

  const handleConfirm = () => {
    const isNotesEditMode = !isEditMode && noteEditMode;
    const isNotesTab = isNotesEditMode && selectedTab === 'notes';

    if (isNotesTab) {
      NotesActions.updateNote();
    } else {
      EditSessionActions.checkConfirmation();
    }
  };

  const toggleNoteEditModes = () => {
    if (editModes.get('admin_note')) {
      NotesActions.toggleEditMode('admin_note');
    }
    if (editModes.get('client_note')) {
      NotesActions.toggleEditMode('client_note');
    }
  };

  const handleCancel = () => {
    const isNotesEditMode = !isEditMode && noteEditMode;
    const isNotesTab = isNotesEditMode && selectedTab === 'notes';

    if (isNotesTab) {
      toggleNoteEditModes();
    } else {
      EditSessionActions.cancelChanges();
      SessionSummaryDrawerActions.setEditMode(false);
    }
  };

  if (isEditMode || noteEditMode) {
    return (
      <EditingFooter
        isActiveSubmit={isActiveSubmit}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    );
  }

  if (checkInMode) {
    return <CheckInFooter numberOfClients={clientsToCheckInOutCount} />;
  }

  return null;
}

Footer.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  clientsToCheckInOutCount: PropTypes.number.isRequired,
  isActiveSubmit: PropTypes.bool.isRequired,
  checkInMode: PropTypes.bool.isRequired,
  editModes: PropTypes.instanceOf(Map).isRequired,
};

export default React.memo(Footer);
