import { List } from 'immutable';
import { PaymentMethodSource, ClientSource } from 'sources';
import { UpperHandStore } from 'dataStores';

import ClientBillingSettingsActions from './Actions';

const fields = [
  'total_credits',
  'agreement_details',
  'total_cash_credits',
  'total_account_credit',
];

class ClientBillingSettingsStore extends UpperHandStore {
  constructor() {
    super();

    this.isLoading = true;
    this.userId = null;
    this.paymentMethods = List();
    this.defaultPaymentMethod = null;
    this.accessToVault = false;
    this.customerUserId = null;

    this.bindListeners({
      mounted: ClientBillingSettingsActions.mounted,

      setDefault: ClientBillingSettingsActions.setDefault,
      setDefaultSuccess: ClientBillingSettingsActions.setDefaultSuccess,
      setDefaultError: ClientBillingSettingsActions.setDefaultError,

      deletePaymentMethod: ClientBillingSettingsActions.deletePaymentMethod,
      deletePaymentMethodSuccess:
        ClientBillingSettingsActions.deletePaymentMethodSuccess,
      deletePaymentMethodError:
        ClientBillingSettingsActions.deletePaymentMethodError,

      listPaymentMethods: ClientBillingSettingsActions.listPaymentMethods,
      listPaymentMethodsSuccess:
        ClientBillingSettingsActions.listPaymentMethodsSuccess,
      listPaymentMethodsError:
        ClientBillingSettingsActions.listPaymentMethodsError,

      updateCustomerUserAccess:
        ClientBillingSettingsActions.updateCustomerUserAccess,
      updateCustomerUserAccessSuccess:
        ClientBillingSettingsActions.updateCustomerUserAccessSuccess,
      updateCustomerUserAccessError:
        ClientBillingSettingsActions.updateCustomerUserAccessError,

      toggleAccountCreditAutoPay:
        ClientBillingSettingsActions.toggleAccountCreditAutoPay,
      toggleAccountCreditAutoPaySuccess:
        ClientBillingSettingsActions.toggleAccountCreditAutoPaySuccess,
      toggleAccountCreditAutoPayError:
        ClientBillingSettingsActions.toggleAccountCreditAutoPayError,

      togglePayLater: ClientBillingSettingsActions.togglePayLater,
      togglePayLaterSuccess: ClientBillingSettingsActions.togglePayLaterSuccess,
      togglePayLaterError: ClientBillingSettingsActions.togglePayLaterError,
    });
  }

  reset() {
    this.isLoading = true;

    this.userId = null;
    this.paymentMethods = List();
    this.defaultPaymentMethod = null;
    this.accessToVault = false;
    this.accountCreditAutoPay = false;
    this.customerUserId = null;
    this.disablePayLater = false;
  }

  mounted([
    userId,
    customerUserId,
    access,
    staffMode,
    accountCreditAutoPay,
    disablePayLater,
  ]) {
    this.reset();
    if (userId) {
      this.userId = userId;
    }
    this.accessToVault = access;
    this.accountCreditAutoPay = accountCreditAutoPay;
    this.customerUserId = customerUserId;
    this.disablePayLater = disablePayLater;
    if (this.accessToVault || !staffMode) {
      this.listPaymentMethods();
    } else {
      this.isLoading = false;
    }
  }

  updateCustomerUserAccess() {
    this.isLoading = true;
    ClientSource.patch({
      id: this.customerUserId,
      recordAttributes: { access_to_vault: !this.accessToVault },
      fields,
      success: ClientBillingSettingsActions.updateCustomerUserAccessSuccess,
      error: ClientBillingSettingsActions.updateCustomerUserAccessError,
    });
  }

  updateCustomerUserAccessSuccess(data) {
    this.isLoading = false;

    this.accessToVault = data.access_to_vault;
  }

  updateCustomerUserAccessError(...args) {
    this.isLoading = false;
    this.notifyError('error updating access', args);
  }

  toggleAccountCreditAutoPay() {
    this.isLoading = true;
    this.accountCreditAutoPay = !this.accountCreditAutoPay;
    ClientSource.patch({
      id: this.customerUserId,
      recordAttributes: {
        account_credit_auto_pay: this.accountCreditAutoPay,
        access_to_vault: this.accessToVault,
      },
      fields,
      success: ClientBillingSettingsActions.toggleAccountCreditAutoPaySuccess,
      error: ClientBillingSettingsActions.toggleAccountCreditAutoPayError,
    });
  }

  toggleAccountCreditAutoPaySuccess(data) {
    this.isLoading = false;

    this.accountCreditAutoPay = data.account_credit_auto_pay;
  }

  toggleAccountCreditAutoPayError(...args) {
    this.isLoading = false;
    this.notifyError('error toggling account credit auto pay', args);
  }

  listPaymentMethods() {
    this.isLoading = true;

    PaymentMethodSource.list({
      userId: this.userId,
      success: ClientBillingSettingsActions.listPaymentMethodsSuccess,
      error: ClientBillingSettingsActions.listPaymentMethodsError,
    });
  }

  listPaymentMethodsSuccess({ payment_methods: paymentMethods }) {
    this.isLoading = false;
    this.isSaving = false;
    this.paymentMethods = paymentMethods;
    this.defaultPaymentMethod = this.paymentMethods.find(pm =>
      pm.get('default')
    );
  }

  listPaymentMethodsError(...args) {
    this.isLoading = false;
    this.notifyError('error listing payment methods', args);
  }

  setDefault(paymentMethodId) {
    PaymentMethodSource.update({
      userId: this.userId,
      paymentMethodId,
      recordAttributes: {
        default: true,
      },
      success: ClientBillingSettingsActions.setDefaultSuccess,
      error: ClientBillingSettingsActions.setDefaultError,
    });
  }

  setDefaultSuccess() {
    this.listPaymentMethods();
  }

  setDefaultError(...args) {
    this.notifyError('error setting default payment method', args);
  }

  deletePaymentMethod(paymentMethodId) {
    PaymentMethodSource.destroy({
      userId: this.userId,
      paymentMethodId,
      success: ClientBillingSettingsActions.deletePaymentMethodSuccess,
      error: ClientBillingSettingsActions.deletePaymentMethodError,
    });
  }

  deletePaymentMethodSuccess() {
    this.listPaymentMethods();
  }

  deletePaymentMethodError(...args) {
    this.notifyError('error deleting payment method', args);
  }

  togglePayLater() {
    this.isLoading = true;
    this.disablePayLater = !this.disablePayLater;

    ClientSource.patch({
      id: this.customerUserId,
      recordAttributes: {
        disable_pay_later: this.disablePayLater,
        access_to_vault: this.accessToVault,
      },
      fields,
      success: ClientBillingSettingsActions.togglePayLaterSuccess,
      error: ClientBillingSettingsActions.togglePayLaterError,
    });
  }

  togglePayLaterSuccess() {
    this.isLoading = false;
  }

  togglePayLaterError() {
    this.disablePayLater = !this.disablePayLater;
    this.isLoading = false;
  }
}

export default alt.createStore(
  ClientBillingSettingsStore,
  'ClientBillingSettingsStore'
);
