import * as React from 'react';
import AltContainer from 'alt-container';
import moment from 'moment-timezone';

import CalendarView from 'calendar/components/_CalendarView.jsx';
import CustomizeCalendarDrawer from 'calendar/components/customize_calendar_drawer/CustomizeCalendarDrawer.jsx';
import FilterDrawer from 'event_mgmt/index/components/_FilterDrawer.jsx';
import QuickSchedule from 'calendar/components/quick_schedule/QuickSchedule.jsx';
import Toolbar from 'calendar/components/_Toolbar.jsx';

import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';

import CalendarStore from 'calendar/stores/CalendarStore.jsx';
import CalendarViewStore from 'calendar/stores/CalendarViewStore.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import FilterStore from 'event_mgmt/index/stores/FilterStore.jsx';
import QSClientListStore from 'calendar/stores/QSClientListStore';
import QSClientStore from 'calendar/stores/QSClientStore';
import QuickScheduleStore from 'calendar/stores/QuickScheduleStore.jsx';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import StaffStore from 'shared/stores/StaffStore.jsx';
import { SessionDataStore } from 'dataStores';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';

import SessionSummaryDrawer from 'containers/events/admin/sessionSummaryDrawer/SessionSummaryDrawer.jsx';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffAvailabilityActions from 'shared/actions/StaffAvailabilityActions.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import CalendarFilterDrawer from 'calendar/components/_FilterDrawer.jsx';

const styles = {
  calendarWrap: {
    height: '100vh',
    overflow: 'auto',
  },
};

/**
 *
 * @param {moment} date
 */
const initializeCalendar = date => {
  CalendarViewActions.list();
  EventTypeListingActions.list();
  StaffActions.list({});
  StaffAvailabilityActions.list();
  if (currentCustomer().resources_enabled) {
    ResourceListingActions.paginationOptionsUpdated(1, 100);
  }
  MembershipListingActions.listRequested({});

  CalendarActions.setView(CALENDAR_TYPE.DAY);
  CalendarActions.setCalendarDate(date);
};

function FilterDrawerWrapper({ filterStore, eventTypes }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FilterDrawer showStatusesInFilter {...filterStore} {...eventTypes} />;
}

function CalendarWrap(props) {
  const {
    calendarStore,
    calendarViewStore,
    eventTypes,
    filterStore,
    qsClientListStore,
    qsClientStore,
    quickScheduleStore,
    resourceListingStore,
    staffStore,
    sessionStore,
  } = props;

  const responsiveStyles = smallScreen()
    ? `
      #main-content-wrapper {
        margin-bottom: 0 !important;
      }
    `
    : '';

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const calendarEl = document.getElementById('calendar-content');
      const qsEl = document.getElementById('qs-dialog-content');

      if (calendarEl && qsEl && quickScheduleStore.open) {
        window.visualViewport.onresize = () => {
          calendarEl.style.height = `${window.visualViewport.height}px`;
          qsEl.style.top = `${window.visualViewport.height / 2}px`;
        };
      }

      if (calendarEl && qsEl && !quickScheduleStore.open) {
        calendarEl.style.height = '100vh';
        qsEl.style.top = '50%';
        window.visualViewport.onresize = () => null;
      }
    });

    return () => clearTimeout(timeout);
  }, [quickScheduleStore.open]);

  return (
    <div
      id="calendar-content"
      className="iphone-x-content"
      style={styles.calendarWrap}
    >
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: responsiveStyles }}
      />
      <Toolbar calendarStore={calendarStore} />
      <CalendarView
        calendarStore={calendarStore}
        calendarViewStore={calendarViewStore}
        resourceStore={resourceListingStore}
        staffStore={staffStore}
        sessionStore={sessionStore}
      />
      <QuickSchedule
        quickScheduleStore={quickScheduleStore}
        qsClientListStore={qsClientListStore}
        qsClientStore={qsClientStore}
        staffStore={staffStore}
        resourceListingStore={resourceListingStore}
      />
      <FilterDrawerWrapper filterStore={filterStore} eventTypes={eventTypes} />
      <CustomizeCalendarDrawer calendarViewStore={calendarViewStore} />
      <SessionSummaryDrawer
        allowMinimization
        defaultSelectedTab={calendarStore.sessionSummaryDrawer.selectedTab}
        classes={calendarStore.sessionSummaryDrawer.classes}
        eventId={calendarStore.sessionSummaryDrawer.eventId}
        isOpen={calendarStore.sessionSummaryDrawer.isOpen}
        sessionId={calendarStore.sessionSummaryDrawer.sessionId}
        onClose={CalendarActions.viewSessionClosed}
      />
      <QuickpayModal onSuccess={CalendarActions.list.defer} />
      <RefundModal />
      <CalendarFilterDrawer
        calendarStore={calendarStore}
        allStaff={staffStore.allStaff}
      />
    </div>
  );
}

class Calendar extends React.Component {
  componentDidMount() {
    initializeCalendar(moment());
  }

  render() {
    return (
      <AltContainer
        stores={{
          calendarStore: CalendarStore,
          calendarViewStore: CalendarViewStore,
          eventStore: EventStore,
          eventTypes: EventTypeListingStore,
          filterStore: FilterStore,
          qsClientListStore: QSClientListStore,
          qsClientStore: QSClientStore,
          quickScheduleStore: QuickScheduleStore,
          resourceListingStore: ResourceListingStore,
          staffStore: StaffStore,
          sessionStore: SessionDataStore,
        }}
      >
        <CalendarWrap />
      </AltContainer>
    );
  }
}

export default Calendar;
