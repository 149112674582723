import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography, Tabs } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId } from 'shared/utils/LocaleUtils';

import LocationStore from 'shared/stores/LocationStore.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import ConfirmChangesModal from './components/ConfirmChangesModal.jsx';
import DateTimeContent from './components/DateTimeContent.jsx';
import SessionPreferencesContent from './components/SessionPreferencesContent.jsx';

import EditSessionActions from './actions.js';
import EditSessionStore from './store.js';

import './styles.scss';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    flexGrow: 1,
  },
  childrenContainer: {
    margin: '16px 8px 0',
  },
  title: {
    marginBottom: 16,
  },
  formElement: {
    margin: '16px 0 8px 0',
  },
  textField: {
    '& .UH-MuiInputBase-root': {
      height: '42px',
      borderRadius: '2px',
    },
  },
};

function EditSession({
  sessionId = null,
  intl = {},
  locationStore = {},
  isTeamEvent = false,
  editSessionStore = {},
}) {
  React.useEffect(() => {
    if (sessionId) {
      EditSessionActions.mounted.defer(sessionId, isTeamEvent);
    }

    if (isTeamEvent && sessionId) {
      LocationActions.list();
    }
  }, [sessionId, isTeamEvent]);

  const [activeTab, setActiveTab] = React.useState('date-time');
  const { data, isConfirmationOpen, scheduleLoading } = editSessionStore;

  return (
    <div className="edit-date-time-container" style={styles.mainContainer}>
      <Tabs
        classes={{
          root: 'session-edit',
          tabsRoot: 'session-edit__tabs',
          tabRoot: 'session-edit__tab',
        }}
        value={activeTab}
        tabItems={[
          {
            label: (
              <Typography variant="subtitle2">
                <FormattedMessage
                  id={messageId('.date_time', __filenamespace)}
                />
              </Typography>
            ),
            content: (
              <DateTimeContent
                data={data}
                sessionId={sessionId}
                onChange={EditSessionActions.update}
              />
            ),
            value: 'date-time',
            key: 'date-time',
          },
          {
            label: (
              <Typography variant="subtitle2">
                <FormattedMessage
                  id={messageId('.session_preferences', __filenamespace)}
                />
              </Typography>
            ),
            content: (
              <SessionPreferencesContent
                intl={intl}
                sessionId={sessionId}
                isTeamEvent={isTeamEvent}
                isLoading={scheduleLoading}
                editSessionStore={editSessionStore}
                locationStore={locationStore}
              />
            ),
            value: 'session-preferences',
            key: 'session-preferences',
          },
        ]}
        onChange={(_e, tab) => setActiveTab(tab)}
      />
      <ConfirmChangesModal
        isOpen={isConfirmationOpen}
        intl={intl}
        sessionId={sessionId}
        note={data.get('note')}
        onDisableRescheduleNotification={
          EditSessionActions.toggleRescheduleNotification
        }
      />
    </div>
  );
}

EditSession.propTypes = {
  editSessionStore: PropTypes.object,
  intl: PropTypes.object,
  sessionId: PropTypes.string.isRequired,
  locationStore: PropTypes.object,
  isTeamEvent: PropTypes.bool,
};

export default compose(
  altContainer({
    stores: {
      editSessionStore: EditSessionStore,
      locationStore: LocationStore,
    },
  })
)(EditSession);
