import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Tabs } from '@upperhand/playmaker';

import BalanceListDrawer from 'containers/reports/balanceListDrawer/BalanceListDrawer.jsx';
import OrderSummaryDrawer from 'containers/reports/orderSummaryDrawer/OrderSummaryDrawer.jsx';
import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import CreditListDrawer from 'containers/creditListDrawer/CreditListDrawer.jsx';
import CancellationDialog from 'memberships/components/_CancellationDialog.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';
import SessionSummaryDrawer from 'containers/events/admin/sessionSummaryDrawer/SessionSummaryDrawer.jsx';
import SchedulingDrawer from 'shared/components/SchedulingDrawer.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import ScheduleCancelRegistrationModal from 'containers/clientProfile/components/Schedule/CancelRegistrationModal.jsx';
import ClientTermDialog from 'customers/ClientTermDialog.jsx';
import SuspensionDialog from 'memberships/components/_SuspensionDialog.jsx';
import ReactivationDialog from 'memberships/components/_ReactivationDialog.jsx';
import BalanceInfo from 'containers/clientProfile/components/BalanceInfo.jsx';
import ContactInfo from 'containers/clientProfile/components/ContactInfo.jsx';
import CreditInfo from 'containers/clientProfile/components/CreditInfo.jsx';
import AccountCreditsInfo from 'containers/clientProfile/components/AccountCredits/AccountCreditsInfo.jsx';
import CashCreditsInfo from 'containers/clientProfile/components/CashCreditsInfo.jsx';
import ManagedProfiles from 'containers/clientProfile/components/ManagedProfiles.jsx';
import MembershipInfo from 'containers/clientProfile/components/MembershipInfo/MembershipInfo.jsx';
import MembershipEmbedInfo from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedInfo.jsx';
import Name from 'containers/clientProfile/components/Name.jsx';
import Notes from 'containers/clientProfile/components/Notes.jsx';
import Orders from 'containers/clientProfile/components/Orders/OrdersList.jsx';
import PaymentMethods from 'containers/clientProfile/components/Payments/PaymentMethods.jsx';
import Schedules from 'containers/clientProfile/components/Schedule/Schedules.jsx';
import Announcement from 'containers/clientProfile/components/Announcement.jsx';
import Header from 'containers/clientProfile/components/Header.jsx';
import EditMembershipExpireModal from 'containers/clientProfile/components/EditMembershipExpireModal/index.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ChargeDateChangeDialog from 'memberships/components/_ChargeDateChangeDialog.jsx';
import { Content } from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/ClientProfileDrawer.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { isDBatTheme, isTexasTwelveTheme } from 'shared/utils/ThemeUtils';

import {
  ClientDataStore,
  MembershipSubscriptionDataStore,
  RegistrationDataStore,
  SessionDataStore,
} from 'dataStores';
import SessionSummaryDrawerStore from 'containers/events/admin/sessionSummaryDrawer/Store';
import ClientProfileStore from 'containers/clientProfile/Store';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import ClientProfileActions from 'containers/clientProfile/Actions';

import PaymentDetailsDrawer from 'containers/reports/paymentDetailsDrawer/PaymentDetailsDrawer.jsx';
import Attachments from './components/Attachments/Attachments.jsx';
import AdditionalEmails from './components/AdditionalEmails/_AdditionalEmails.jsx';

import './styles.scss';

const onTabChange = (_event, tab) => ClientProfileActions.tabSelected(tab);
const onClientSave = (client, attributes) => {
  ClientProfileActions.clientSaved({ client, attributes });
};
const onPdfSave = (client, document) => {
  ClientProfileActions.documentSelected({ client, document });
};
const onImageSelect = (client, image) =>
  ClientProfileActions.imageSelected({ client, image });

function ClientProfile({
  intl,
  disableAutoFetch,
  clientId: urlClientId,
  clientProfileStore: {
    selectedSessionId,
    selectedEventId,
    selectedRegistrationId,
    clientId,
    userId,
    accessToVault,
    selectedTab,
    managedProfileIds,
    subscriptionId,
    outstandingBalance,
    totalCredits,
    totalAccountCredit,
    totalCashCredits,
    orderIds,
    registrationIds,
    pastRegistrationIds,
    upcomingLoading,
    pastLoading,
    tabLoading,
    pagination,
    registrationToRemove,
    eventForRemovingRegistration,
    waiveBalance,
    creditOperation,
    isLoading,
    currentEmailData,
    fieldErrors,
    additionalEmailsList,
    isModalOpen,
    defaultSelectedTab,
  },
  clientDataStore: { clients },
  membershipSubscriptionDataStore: { membershipSubscriptions },
  sessionSummaryDrawerStore: { selectedTab: ssdSelectedTab },
  registrationDataStore: { registrations },
  sessionDataStore: { sessions },
}) {
  React.useEffect(() => {
    if (!disableAutoFetch) {
      ClientProfileActions.mounted({
        clientId: urlClientId,
        openDrawer: !smallScreen(),
      });
    }
    return () => {
      if (!disableAutoFetch) {
        ClientProfileActions.closeDrawer();
        ClientProfileDrawerActions.closeDrawer();
      }
    };
  }, [disableAutoFetch, urlClientId]);

  const client = clients.get(clientId);
  const isStaff = currentUser().isStaff();
  const isManagedBy = currentUser().managed_by_id;
  const isAcceptTerms = client?.get('has_accepted_terms');
  const isLoginEnabled = client?.get('login_enabled');
  const showTermsDialog = isStaff && !isAcceptTerms && isLoginEnabled;
  const isDBatInstance = isDBatTheme() && currentUser().isClient();
  const showBalanceBlock =
    !isManagedBy && !client?.get('managed_by_id') && !isDBatInstance;
  const showOrdersTab = !isTexasTwelveTheme()
    ? !isManagedBy
    : !isManagedBy && !currentUser().isManager();
  const allRegistrationIds = registrationIds
    .concat(pastRegistrationIds)
    .toArray();

  const handleNextSession = () => {
    const index = allRegistrationIds.indexOf(selectedRegistrationId);
    const nextRegistrationId = allRegistrationIds[index + 1];
    const nextRegistration = registrations.get(nextRegistrationId);
    const nextSessionId = nextRegistration?.session_id;

    const nextSession = sessions.get(nextSessionId);
    const nextEventId = nextSession?.event_id;

    if (nextSessionId)
      ClientProfileActions.openSessionSummaryDrawer({
        sessionId: nextSessionId,
        eventId: nextEventId,
        defaultSelectedTab: ssdSelectedTab,
        registrationId: nextRegistrationId,
      });
  };

  const handlePreviousSession = () => {
    const index = allRegistrationIds.indexOf(selectedRegistrationId);
    const previousRegistrationId = allRegistrationIds[index - 1];
    const previousRegistration = registrations.get(previousRegistrationId);
    const previousSessionId = previousRegistration?.session_id;

    const previousSession = sessions.get(previousSessionId);
    const previousEventId = previousSession?.event_id;

    if (previousSessionId) {
      ClientProfileActions.openSessionSummaryDrawer({
        sessionId: previousSessionId,
        eventId: previousEventId,
        defaultSelectedTab: ssdSelectedTab,
        registrationId: previousRegistrationId,
      });
    }
  };

  const tabs = [
    {
      label: t('.schedule', intl, __filenamespace),
      content: (
        <Schedules
          pastRegistrationIds={pastRegistrationIds}
          registrationIds={registrationIds}
          loading={tabLoading}
          upcomingLoading={upcomingLoading}
          pastLoading={pastLoading}
          pagination={pagination}
          intl={intl}
        />
      ),
      visible: true,
    },
    {
      label: t('.orders', intl, __filenamespace),
      content: (
        <Orders
          orderIds={orderIds}
          loading={tabLoading}
          pagination={pagination}
          intl={intl}
        />
      ),
      visible: showOrdersTab,
    },
    {
      label: t('.payments', intl, __filenamespace),
      content: (
        <PaymentMethods
          clientId={clientId}
          userId={userId}
          accessToVault={accessToVault}
          accountCreditAutoPay={client?.get('account_credit_auto_pay')}
          disablePayLater={client?.get('disable_pay_later')}
          accountCredits={totalAccountCredit}
          intl={intl}
        />
      ),
      visible: !isManagedBy,
    },
  ].filter(tab => tab.visible);

  if (smallScreen()) {
    return (
      <SpinWhileLoading isLoading={isLoading}>
        <div className="client-profile-mobile">
          <Header intl={intl} clientId={clientId} />
          <Content
            useOutsideDrawer
            clientId={clientId}
            clientObj={client}
            intl={intl}
            totalCredits={totalCredits}
            totalAccountCredit={totalAccountCredit}
            totalCashCredits={totalCashCredits}
            managedProfileIds={managedProfileIds}
            outstandingBalance={outstandingBalance}
            subscriptionId={subscriptionId}
            membershipSubscriptions={membershipSubscriptions}
            currentEmailData={currentEmailData}
            fieldErrors={fieldErrors}
            additionalEmailsList={additionalEmailsList}
            isModalOpen={isModalOpen}
          />
          <Announcement />
        </div>
      </SpinWhileLoading>
    );
  }

  return (
    <SpinWhileLoading isLoading={isLoading}>
      <div className="client-profile">
        <Header intl={intl} clientId={clientId} />
        <Grid className="client-profile__content" container>
          <Grid
            className="client-profile__content-column"
            container
            item
            xs={12}
            md={4}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Name
                  intl={intl}
                  clientId={clientId}
                  subscriptionId={subscriptionId}
                  membershipSubscriptions={membershipSubscriptions}
                  onImageSelect={onImageSelect}
                  onClientSave={onClientSave}
                  onPrimaryAccountClick={data =>
                    ClientProfileActions.mounted(data)
                  }
                />
              </Grid>
              {subscriptionId && (
                <Grid item xs={12}>
                  <MembershipInfo
                    showTitle={false}
                    clientId={clientId}
                    subscriptionId={subscriptionId}
                    managedProfileIds={managedProfileIds}
                    intl={intl}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <MembershipEmbedInfo subscriptionId={subscriptionId} />
              </Grid>
              <Grid item xs={12}>
                <ContactInfo
                  clientId={clientId}
                  onClientSave={onClientSave}
                  onVerifyEmail={() =>
                    ClientProfileActions.verifyEmail(client.email)
                  }
                  onResetPassword={() =>
                    ClientProfileActions.resetPassword(client.email)
                  }
                  intl={intl}
                />
              </Grid>
              {isStaff && (
                <Grid item xs={12}>
                  <Notes
                    clientId={clientId}
                    intl={intl}
                    onClientSave={onClientSave}
                  />
                </Grid>
              )}
              {isStaff && (
                <Grid item xs={12}>
                  <Attachments
                    clientId={clientId}
                    intl={intl}
                    onClientSave={onPdfSave}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <AdditionalEmails
                  actions={ClientProfileActions}
                  additionalEmailsList={additionalEmailsList}
                  currentEmailData={currentEmailData}
                  fieldErrors={fieldErrors}
                  isModalOpen={isModalOpen}
                  intl={intl}
                />
              </Grid>
              <Grid item xs={12}>
                <ManagedProfiles
                  useOutsideDrawer
                  clientId={clientId}
                  managedProfileIds={managedProfileIds}
                  intl={intl}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="client-profile__content-column" item xs={12} md={8}>
            <Grid item container xs={12} spacing={2}>
              {showBalanceBlock && (
                <Grid item>
                  <BalanceInfo
                    intl={intl}
                    clientId={clientId}
                    outstandingBalance={outstandingBalance}
                  />
                </Grid>
              )}
              <Grid item>
                <CreditInfo
                  intl={intl}
                  clientId={clientId}
                  totalCredits={totalCredits}
                />
              </Grid>
              <Grid item>
                <AccountCreditsInfo
                  clientId={clientId}
                  creditsAmount={totalAccountCredit}
                />
              </Grid>
              <Grid item>
                <CashCreditsInfo
                  intl={intl}
                  clientId={clientId}
                  totalCredits={totalCashCredits}
                  subscriptionId={subscriptionId}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Tabs
                variant="fullWidth"
                tabItems={tabs}
                onChange={onTabChange}
                value={selectedTab}
              />
            </Grid>
          </Grid>
        </Grid>
        <EditMembershipExpireModal />
        {showTermsDialog && (
          <ClientTermDialog client={client} clientId={client?.id} />
        )}
        <Announcement />
        <SuspensionDialog />
        <ChargeDateChangeDialog />
        <ReactivationDialog />
        <CancellationDialog />
        <BalanceListDrawer />
        <CreditListDrawer />
        <OrderSummaryDrawer />
        <BalanceDetailsDrawer withBackLink />
        <PaymentDetailsDrawer />
        <RefundModal />
        <PaymentPlanDetailsDrawer withBackLink />
        <SchedulingDrawer />
        <QuickpayModal
          onSuccess={ClientProfileActions.onPaymentSuccess.defer}
        />
        <SessionSummaryDrawer
          defaultSelectedTab={defaultSelectedTab || ssdSelectedTab}
          isOpen={!!selectedSessionId}
          sessionId={selectedSessionId}
          eventId={selectedEventId}
          onClose={ClientProfileActions.closeSessionSummaryDrawer}
          onNextSession={handleNextSession}
          onPreviousSession={handlePreviousSession}
        />
        <ScheduleCancelRegistrationModal
          intl={intl}
          registration={registrationToRemove}
          event={eventForRemovingRegistration}
          waiveBalance={waiveBalance}
          creditOperation={creditOperation}
          onCancel={() =>
            ClientProfileActions.setRegistrationToRemove({
              registration: null,
              event: null,
            })
          }
          onCreditOperationChange={ClientProfileActions.setCreditOperation}
          onWaiveChange={ClientProfileActions.setWaive}
          onConfirm={ClientProfileActions.registrationCancel}
        />
      </div>
    </SpinWhileLoading>
  );
}

export default altContainer({
  stores: {
    clientProfileStore: ClientProfileStore,
    clientDataStore: ClientDataStore,
    membershipSubscriptionDataStore: MembershipSubscriptionDataStore,
    sessionSummaryDrawerStore: SessionSummaryDrawerStore,
    registrationDataStore: RegistrationDataStore,
    sessionDataStore: SessionDataStore,
  },
})(injectIntl(ClientProfile));
